:root {
  --primary-color: #007bff;
  --secondary-color: #555;
  --bg-color: #f9f9f9;
  --border-color: #ccc;
  --font-family: Arial, sans-serif;
  --padding: 10px;
  --border-radius: 5px;
}

body {
  font-family: var(--font-family);
}

header {
  background-color: var(--primary-color);
  padding: var(--padding) 20px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  color: white;
  font-size: 1.5em;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.nav-links a {
  color: white;
  text-decoration: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

main {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
}

h1 {
  color: var(--primary-color);
}

p {
  color: var(--secondary-color);
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button.red {
  background-color: #af4154;
}
button.red:hover {
  background-color: #af4154;
  opacity: 0.7;
}

/* Home Page */
.home-page main > h1,
.home-page main > p {
  text-align: center;
}

.search-bar {
  display: flex;
  margin-bottom: 20px;
}

.search-bar input {
  width: 100%;
  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius) 0 0 var(--border-radius);
}

.search-bar button {
  border-radius: 0 var(--border-radius) var(--border-radius) 0;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-group {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.filter-group > select {
  flex: 1 1 auto;
  min-width: 150px;
}
.filter-group > input {
  flex: 1 1 auto;
  min-width: 150px;
}

.filters input,
.filters select {
  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.ads-count {
  margin-bottom: 20px;
  font-weight: bold;
}

.ads-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ad-item {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  overflow: hidden;
}

.image-container {
  position: relative;
  display: flex;
  width: 250px;
  background-size: contain; /* Adapter la taille de l'image */
  background-repeat: no-repeat; /* Ne pas répéter l'image */
  background-position: top; /* Centrer l'image */
}

.ad-item img {
  z-index: -1;
  width: 250px;
  object-fit: cover;
}

@media (max-width: 768px) {
  .ad-item {
    flex-wrap: wrap;
  }

  .image-container {
    width: 100%;
  }

  .ad-item img {
    width: 100%;
    object-fit: cover;
  }
}

.ad-details {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  background-color: var(--bg-color);
}

.ad-details h3 {
  margin-top: 0;
}

.ad-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  align-self: end;
  text-align: right;
  padding-top: 20px;
  margin-top: auto;
}

.ad-price {
  font-size: 1.2em;
  font-weight: bold;
  color: var(--primary-color);
  white-space: nowrap;
}

.pagination {
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-top: 20px;
}

.pagination ul {
  display: inline-block;
  padding: 0;
}

.pagination li {
  display: inline-block;
}

.pagination li > a {
  padding: 10px;
  border: 1px solid var(--border-color);
  background-color: white;
  cursor: pointer;
  color: var(--primary-color);
}

.pagination li > a:hover {
  background-color: #f1f1f1;
}

.pagination li.selected > a {
  background-color: var(--primary-color);
  color: #ffffff;
}

.pagination li.selected a {
  color: #ffffff;
}

/* Ad Detail Page */
.ad-detail-page .back-link {
  color: var(--primary-color);
  text-decoration: none;
  display: inline-block;
  margin-bottom: 20px;
}

.ad-detail-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ad-detail-main {
  display: flex;
  gap: 20px;
}

.ad-images {
  flex: 1;
}

.ad-images img {
  width: auto;
  border-radius: var(--border-radius);
  max-height: 650px;
  max-width: 100%;
}

.ad-thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.ad-thumbnails img {
  width: 50px;
  height: 50px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.ad-price-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.ad-price-container {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  padding: 20px;
}

.ad-price {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--primary-color);
}

.ad-description h1 {
  margin: 0 0 10px;
  color: var(--primary-color);
}

.ad-description p {
  color: var(--secondary-color);
}

.ad-characteristics,
.ad-options {
  background-color: var(--bg-color);
  padding: 20px;
  border-radius: var(--border-radius);
}

.ad-characteristics h2,
.ad-options h2 {
  margin: 0 0 20px;
  color: var(--primary-color);
}

.ad-characteristics table {
  width: 100%;
}

.ad-characteristics td {
  padding: 5px 10px;
}

.option-category h3 {
  margin: 10px 0;
  color: var(--secondary-color);
}

.option-category ul {
  list-style: none;
  padding: 0;
}

.option-category li {
  margin: 5px 0;
}

.similar-ads {
  margin-top: 40px;
}

.similar-ads h2 {
  margin: 0 0 20px;
  color: var(--primary-color);
}

.similar-ads-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.similar-ad-item {
  flex: 1;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  white-space: nowrap;
}

.similar-ad-item img {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.similar-ad-details {
  padding: 10px;
}

.similar-ad-details h3 {
  margin: 0 0 10px;
}

.view-all-link {
  display: inline-block;
  margin-top: 20px;
  color: var(--primary-color);
  text-decoration: none;
}

/* Add Ad Page */
.add-ad-page h1 {
  text-align: center;
}

.add-ad-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--primary-color);
}

input,
textarea,
select {
  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

textarea {
  resize: vertical;
  height: 100px;
}

.characteristics-group,
.options-group,
.photos-group {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  padding: 20px;
  background-color: var(--bg-color);
}

h2 {
  margin: 0 0 20px;
  color: var(--primary-color);
}

.options-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.option-item {
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  padding: 5px 10px;
  border-radius: var(--border-radius);
}

.option-item button {
  background: none;
  border: none;
  font-size: 1em;
  margin-left: 10px;
  cursor: pointer;
}

.option-item button:hover {
  color: #af4154;
}

.photos-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.photo-item {
  position: relative;
}

.photo-item img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: var(--border-radius);
}

.photo-item .button-container {
  display: flex;
  position: absolute;
  top: 5px;
  right: 5px;
  gap: 5px;
}

.photo-item button {
  border: none;
  font-size: 1em;
  cursor: pointer;
  width: auto;
  height: auto;
}

.photo-item button:hover {
  color: #af4154;
}

/* My Account Page */

.my-account-page h1 {
  margin-bottom: 20px;
  text-align: center;
}

.account-form,
.password-form {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

input {
  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

/* My Ads Page */
.my-ads-page h1 {
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  padding: 10px 20px;
  border: 1px solid var(--border-color);
  color: var(--primary-color);
  background-color: white;
  cursor: pointer;
}

.tabs button.active {
  background-color: var(--primary-color);
  color: white;
}

/* .ads-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
} */

/* .ad-item {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  overflow: hidden;
  width: 200px;
  text-align: center;
} */

/* .ad-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
} */

/* .ad-details {
  padding: 10px;
}

.ad-details h3 {
  margin: 10px 0;
} */

/* button {
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
} */

/* Admin */

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #007bff;
  color: #fff;
}

thead th {
  padding: 12px 6px;
  text-align: left;
  font-weight: normal;
}

tbody tr {
  border-bottom: 1px solid #e0e0e0;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

tbody tr:hover {
  background-color: #e9f5ff;
}

tbody th,
tbody td {
  padding: 12px 6px;
  text-align: left;
}

tbody th {
  font-weight: normal;
  color: #555;
}

tbody td {
  color: #333;
}

tbody td:last-child {
  text-align: right;
}

/* MODAL */

/* Style pour masquer la modal par défaut */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding-top: 60px;
}

/* Contenu de la modal */
.modal-content {
  background-color: #fff;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 900px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Bouton de fermeture */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#openModalBtn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#openModalBtn:hover {
  background-color: #0056b3;
}
