.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Couleur de fond semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Assurez-vous que le loader est au-dessus de tout le reste */
  overflow: hidden; /* Bloque le scroll */
}

.spinner {
  border: 16px solid #f3f3f3; /* Gris clair */
  border-top: 16px solid #3498db; /* Bleu */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
